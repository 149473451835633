import * as React from "react";
import { graphql, PageProps } from "gatsby";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import { DefiMarketQuery } from "../../graphql-types";
import Markets from "../components/pages/markets/markets";

type DefiMarketPageProps = PageProps<DefiMarketQuery>;

const DefiMarketPage: React.FC<DefiMarketPageProps> = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Decentralized Finance(DeFi) stats, prices and market insights"
        description="Decentralized Finance(DeFi) market overview, prices, market capitalization, charts, exchanges and analysis."
      />

      <Markets isDefi={true} staticData={data} />
    </Layout>
  );
};

export default DefiMarketPage;

export const query = graphql`
  query DefiMarket {
    allCoinListItem(limit: 6, filter: { is_defi: { eq: true } }) {
      nodes {
        id
        slug
        symbol
        name
        image
        current_price
        price_change_percentage_24h
      }
    }
    allExchangeListItem(
      limit: 6
      filter: { is_dex: { eq: true } }
      sort: { fields: [trade_volume_24h_btc], order: [DESC] }
    ) {
      nodes {
        id
        slug
        name
        image
        trade_volume_24h_btc
      }
    }
  }
`;
